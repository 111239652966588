import React, { useCallback } from "react";
import { Modal } from "../../../ui/modal/Modal";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { singleUploadSellerCreditNote, singleUploadSellerInvoice } from "../../../../actions/billings";

interface Props {
  payment_id: number;
  onModalClose: any;
  credit_note?: boolean;
}

const SellerInvoiceUploadModal: React.FC<Props> = ({
  onModalClose,
  payment_id,
  credit_note
}) => {
  const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone({
    accept: [".pdf"],
    onDropRejected: () => {
      toast.error('File type rejected. Only ".pdf" files are allowed.');
    },
  });

  const onSubmit = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();

      try {
        let data;        
        data = credit_note 
          ? await singleUploadSellerCreditNote(acceptedFiles[0], `${payment_id}`)
          : await singleUploadSellerInvoice(acceptedFiles[0], `${payment_id}`);
        console.log(data);
        if (data.success) {
          onModalClose(e);
          return toast.success(data.message, { autoClose: 5000 });
        } else {
          return toast.error(data.message, { autoClose: false });
        }
      } catch (error:any) {
        return toast.error(error.message, { autoClose: false });
      }
    },
    [acceptedFiles]
  );

  const label_title = credit_note ? "Credit Note Upload" :  "Seller Invoice Upload";
  const label_text = credit_note ? "credit note" :  "invoice";

  return (
    <Modal onModalClose={onModalClose} isOpened={true}>
      <div className="table-modal-form-cont in-column justify-center p4" style={{width:430}}>
        <div className="table-modal-title-box m0">
          <div className="import-title">{label_title}</div>
        </div>
        <div className="table-modal-border"></div>
        <p className="text-align-left width100">
          Upload {label_text} for payment ID {payment_id}
        </p>

        <span className="mt2 in-row width100 align-center">
          <b className="mr1">Add {label_text}</b>
          (.pdf file)
        </span>
        <div
          className="width100 pointer import-reviews-dropzone"
          style={{ width: "400px", height: "150px", background: "" }}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <h4>
              <img src="/icons/upload-spreadsheet.svg" alt="" />
              Drop the file here
            </h4>
          ) : (
            <h2 className="pc-modal-dropzone-text">
              {acceptedFiles.length > 0
                ? `Click 'Import' to finish upload ${acceptedFiles[0].name}`
                : <>
                  <img src='/icons/upload-spreadsheet.svg' alt='' />
                  Drop the file here
                </>
              }
            </h2>
          )}
        </div>
        <div className="table-modal-border"></div>
        <div style={{ marginBottom: "0" }} className="table-modal-form-button-box width100">
          <button
            className="sel-modal-form-button-cancel"
            onClick={onModalClose}
          >
            Cancel
          </button>
          <button 
          onClick={(e: React.FormEvent) => {
            onSubmit(e);
          }}
            disabled={!acceptedFiles.length}
            className="table-modal-form-button initial" type="submit">
            Import
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default SellerInvoiceUploadModal;
